<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
<!--          <Sidepanel @onDriverSelected="onDriverSelected" />-->
          <Sidepanel @onDriverSelected="onDriverSelected" @removeDriverSelection="removeDriverDetails()"/>

          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
            <template v-if="driver">

              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">Details of {{driver.name}} {{driver.other_names}}</span>
                  <span class="font-12 badge badge-success p-1 mr-1" v-if="driver.driver">Dossier: {{driver.driver.dossier}}</span>
                  <span class="font-12 badge badge-dark p-1 " v-if="driver.prospective_driver">
                    {{ driver.prospective_driver.service_type ? formattedService(driver.prospective_driver.service_type) : "No service selected" }}
                  </span>
                </h5>
              </div>

              <!--        Right side panel shows here -->
              <div class="row">
                <div class="col-md-12">
                  <table class="table">
                    <tr>
                      <td>Status </td>
                      <td class="text-right">
                        <span class="badge badge-success" v-if="driver.status ==='active'">{{driver.status}}</span>
                        <span class="badge badge-danger" v-if="driver.status ==='disabled'">{{driver.status}}</span> </td>
                    </tr>

                    <tr>
                      <td>Last Login </td>
                      <td class="text-right"> {{driver.lastLogin}} </td>
                    </tr>

                    <tr>
                      <td>Phone </td>
                      <td class="text-right"> {{driver.phone}} </td>
                    </tr>

                    <template v-if="driver.driver">
                      <tr>
                        <td>Dossier </td>
                        <td class="text-right"> {{ driver.driver.dossier }} </td>
                      </tr>
                    </template>

                    <template v-if="driver.prospective_driver">
                      <tr>
                        <td>Email </td>
                        <td class="text-right"> {{ driver.email }} </td>
                      </tr>
                    </template>


                  </table>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <router-link :to="'/prospective-driver-detail/'+ driver.id" class="btn btn-dark ml-2 mb-1">
                        <i class="fa fa-file"></i> View enrolment documents
                      </router-link>
                      <router-link :to="'/driver/'+ driver.id" class="btn btn-info ml-2 mb-1" v-if="!disableAction">
                        <i class="fas fa-edit"></i>  Edit driver basic info
                      </router-link>
                      <button class="btn btn-primary ml-2 mb-1" @click="changeDriverPassword" v-if="!disableAction">
                        Change driver's password
                      </button>
                      <button class="btn btn-outline-danger ml-2 mb-1" type="button" @click="changeDriverStatus('disabled')" v-if="!disableAction && driver.status ==='active'">
                        Delete Account
                      </button>
                      <button class="btn btn-outline-success ml-2 mb-1" type="button" @click="changeDriverStatus('active')" v-if="!disableAction && driver.status ==='disabled'">
                        Activate Account
                      </button>

                    </div>
                  </div>

                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <template v-if="loadingExemptionStatus">
                        <span>Loading exemption status ... </span>
                      </template>
                      <template v-else>
                        <b-form-checkbox v-model="exemptedFromFinancialData" name="check-button" switch @change="setExemptDriverFromFinancialDataStatus">
                          Exempt from financial data <b>(Status: {{ exemptedFromFinancialData ? 'exempted' : 'not exempted' }})</b>
                        </b-form-checkbox>
                      </template>
                    </div>
                  </div>

                  <hr>
<!--                  template-->
                  <form action="#" @submit.prevent="handleSubmitAssignDriverToProviderExternalId">
                    <h4 class="card-title"> Set external provider's identifier for {{driver.name}} </h4>
                    <template v-if="providers.length <= 0">
                      <skeleton :count="5"></skeleton>
                    </template>
                    <template v-else>
                      <div class="row">
                          <template v-for="data in externalProviderData">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input type="hidden" class="form-control" v-model="data['providerName']" >
                                  <select name="" class="form-control" disabled>
                                    <option :value="provider.id" v-for="provider in providers" :selected="provider.id === data['providerId']">{{provider.name}}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <input type="text" class="form-control" :placeholder="'Enter provider\'s identifier value for ' + driver.name"
                                  v-model="data['providerExternalValue']"
                                >
                              </div>
                          </template>
                          <div class="col-md-12">
                            <div class="form-group">
                              <button class="btn btn-success" type="submit"><i class="fas fa-save"></i> Save</button>
                            </div>
                          </div>
                      </div>
                    </template>
                  </form>

                </div>
              </div>



            </template>
            <div class="text-center mt-5" v-else>
              <h4>Select a driver to view details...</h4>
            </div>
          </div >

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>
  </div>
</template>

<script>
// import Sidepanel from "./sidepanel";
import Sidepanel from "./../payouts/sidepanel";



import appConfig from "../../../../../app.config";
import { Skeleton } from 'vue-loading-skeleton';
import {driversService} from "../../../../apis/drivers.service";
import {confirm} from "../../../../utils/functions";
import {payoutService} from "../../../../apis/payout.service";
import ability from "../../../../utils/defineAbility";

/**
 * Read-email component
 */
export default {
  components: {
    Sidepanel,
    Skeleton
  },
  data() {
    return {
      driver: null,
      externalProviderData: [],
      exemptedFromFinancialData: false,
      loadingExemptionStatus: false,
      disableAction:false
    };
  },
  computed: {
    providers(){
      return this.$store.getters.GET_PROVIDERS
    }
  },
  methods:{
    checkPermission(){
      const user = this.$store.getters.GET_USER_INFO;
      const user_role = user['roles'].filter((e)=>e.toLowerCase() ==='developer' || e.toLowerCase() ==='super admin')
      if(user_role.length ===0){
        const permission = this.$store.getters.GET_USER_INFO['user_permission_obj'];
        let user_permission = permission.find((e)=>e.name ==='manage drivers')
        if(user_permission){
          if(user_permission['write'] === 1){
            this.disableAction = false;
          }else if(user_permission['write'] === 0){
            this.disableAction = true;
          }
        }
      }
    },
    onDriverSelected(driver){
        this.driver = driver;
    },
    changeDriverPassword(){
      if(!this.driver){
        return;
      }

      const payload = {
        'userId': this.driver['id']
      }

      confirm('A random password will be generated and sent to ' + this.driver['name'] + ' via SMS', () => {

        this.$store.dispatch('showLoader')
        driversService.changeDriverPassword(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message: data.message, showSwal:true})
            return;
          }

          return this.$store.dispatch('success', {message: data.message, showSwal:true})

        })

      })

    },
    changeDriverStatus(status){
      if(!this.driver){
        return;
      }

      const payload = {
        'userId': this.driver['id'],
        status
      }

      confirm("Are you sure you want to delete this driver's account", () => {

        this.$store.dispatch('showLoader')
        driversService.changeDriverStatus(payload).then((data) => {
          this.$store.dispatch('hideLoader');

          if(!data.status){
            this.$store.dispatch('error', {message: data.message, showSwal:true})
            return;
          }

           this.$store.dispatch('success', {message: data.message, showSwal:true})
          setTimeout(()=>{
            location.reload();
          },3000)

        })

      })

    },
    formattedService(value){
      return this.$options.filters.serviceType(value);
    },
    getProviderName(id){
      const provider = this.providers.find(e => e.id === id)
      return provider.name;
    },
    handleSubmitAssignDriverToProviderExternalId(){
      const payload = {
        'payload': this.externalProviderData,
        'driver_name': this.driver.name,
        'driver_id': this.driver.id
      }
      this.$store.dispatch('assignProviderExternalIdentifier', payload)
    },

    removeDriverDetails() {
      this.driver = '';
    },

    setExemptDriverFromFinancialDataStatus(status){

      if(!this.driver){
        return;
      }

      const payload = {
        'user_id': this.driver['id'],
        'status': status
      }

      this.loadingExemptionStatus = true
      driversService.setFDataExemptionStatus(payload).then((data) => {
        this.loadingExemptionStatus = false
        if(!data.status){
          return;
        }
        this.exemptedFromFinancialData = data['extra']
      })
    },

    getExemptDriverFromFinancialDataStatus(){

      if(!this.driver){
        return;
      }

      const payload = {
        'user_id': this.driver['id'],
      }

      this.loadingExemptionStatus = true
      driversService.getFDataExemptionStatus(payload).then((data) => {
        this.loadingExemptionStatus = false
        if(!data.status){
          return;
        }
        this.exemptedFromFinancialData = data['extra']
      })
    },

  },
  watch: {
    driver: function (driver) {
      if(driver){
        this.getExemptDriverFromFinancialDataStatus();
        this.$store.dispatch('fetchProviderExternalIdentifier', driver.id)
      }
    },
    '$store.getters.GET_PROVIDERS': function (providers) {
      this.externalProviderData = [];
      for (let i = 0; i < providers.length; i++) {
        let p = providers[i];
        this.externalProviderData.push({
          'providerId': p['id'],
          'providerName': '',
          'providerExternalValue': '',
        })


      }

      const dps = this.$store.getters.GET_DRIVER_PROVIDERS;
      for (let i = 0; i < dps.length; i++) {
        let dp = dps[i];
        const index = this.externalProviderData.findIndex(e => e['providerId'] === dp['provider_id'])
        this.externalProviderData[index] = {
          'providerId': dp['provider_id'],
          'providerName': dp['provider_name'] || this.getProviderName(dp['provider_id']),
          'providerExternalValue': dp['provider_external_identifier'],
        }
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle','Drivers');
    this.checkPermission()
  },


};
</script>

